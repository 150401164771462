.button.button-eid {
  background: #F9F8F4;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 10px);
  box-sizing: border-box;
  text-decoration: none;
  min-height: 70px;
  border: 0;

  margin: 12px 5px; /* Side margins to preserve drop shadow */
  padding: 12px 24px;

  font-family: "Raleway";
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
}

.button.button-eid strong {
  font-weight: 700;
}

.button.button-eid img {
  height: 24px;
  padding-right: 20px;
}

.button.button-eid.button-no-bankid img {
  height: 18px;
}
.button.button-eid.button-dk-nemid {
  color: #0B6062;
}
.button.button-eid.button-dk-nemid img {
  width: 66px;
  height: auto;
}

.button.button-eid.button-dk-mitid {
  background: #0060E6;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  color: white;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 124%;
}

.button.button-eid.button-dk-mitid img {
  width: 66px;
  height: auto;
}

.button.button-eid.button-se-bankid {
  color: #235971;
}
.button.button-eid.button-se-bankid img {
  width: 40px;
  height: auto;
}

.button.button-eid.button-no-bankid img {
  height: 13px;
}

.button.button-eid.button-fi img {
  height: 40px;
}